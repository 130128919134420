import React from 'react';
import { Link, useParams, Navigate } from 'react-router-dom';
import SEO from './SEO';
import StructuredData from './StructuredData';
import blogPosts from '../data/blogPosts';

function BlogPost() {
  const { slug } = useParams();
  const post = blogPosts.find(post => post.slug === slug);

  if (!post) {
    return <Navigate to="/blog" replace />;
  }

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": post.title,
    "description": post.description,
    "image": post.image,
    "author": {
      "@type": "Organization",
      "name": "Nest"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Nest",
      "logo": {
        "@type": "ImageObject",
        "url": "https://nestextended.com/logo.png"
      }
    },
    "datePublished": post.date,
    "dateModified": post.lastModified || post.date,
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `https://nestextended.com/blog/${post.slug}`
    },
    "keywords": post.keywords
  };

  return (
    <>
      <SEO 
        title={`${post.title} | Nest Chrome Tab Manager Blog`}
        description={post.description}
        keywords={post.keywords}
        canonicalUrl={`/blog/${post.slug}`}
        image={post.image}
      />
      <StructuredData data={structuredData} />
      
      <article className="blog-post">
        <header className="post-header">
          {post.image && (
            <img 
              src={post.image} 
              alt={post.imageAlt || post.title}
              className="post-featured-image"
            />
          )}
          <h1>{post.title}</h1>
          <div className="post-meta">
            <time dateTime={post.date}>{new Date(post.date).toLocaleDateString()}</time>
            {post.readingTime && <span className="reading-time">{post.readingTime} min read</span>}
          </div>
        </header>
        
        <div className="post-content-wrapper">
          <div className="post-content" dangerouslySetInnerHTML={{ __html: post.content }} />
        </div>
        
        <footer className="post-footer">
          <div className="post-tags">
            {post.tags.map(tag => (
              <Link key={tag} to={`/blog/tag/${tag}`} className="tag">
                #{tag}
              </Link>
            ))}
          </div>
        </footer>
      </article>
    </>
  );
}

export default BlogPost; 