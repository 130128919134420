import React from 'react';
import { Link } from 'react-router-dom';
import SEO from './SEO';
import StructuredData from './StructuredData';
import blogPosts from '../data/blogPosts';

function Blog() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Blog",
    "name": "Nest Chrome Tab Manager Blog",
    "description": "Expert tips and guides about Chrome tab management, productivity, and browser organization.",
    "url": "https://nestextended.com/blog",
    "publisher": {
      "@type": "Organization",
      "name": "Nest",
      "logo": {
        "@type": "ImageObject",
        "url": "https://nestextended.com/logo.png"
      }
    },
    "blogPost": blogPosts.map(post => ({
      "@type": "BlogPosting",
      "headline": post.title,
      "description": post.description,
      "datePublished": post.date
    }))
  };

  return (
    <>
      <SEO 
        title="Chrome Tab Management Blog | Best Practices & Tips - Nest"
        description="Expert guides on Chrome tab management, browser organization, tab snoozing, and note-taking. Learn how to master your browser with the best Chrome tab manager."
        keywords="chrome tab manager, best tab manager chrome, chrome extension tab manager, chrome notes extension, chrome tab snooze"
        canonicalUrl="/blog"
      />
      <StructuredData data={structuredData} />
      
      <main className="blog-page">
        <section className="blog-hero">
          <h1>Chrome Tab Management Blog</h1>
          <p className="subtitle">Expert tips and guides for mastering your browser productivity</p>
        </section>

        <section className="blog-grid">
          {blogPosts.map(post => (
            <article key={post.slug} className="blog-card">
              <Link to={`/blog/${post.slug}`}>
                <img 
                  src={post.image} 
                  alt={post.imageAlt || post.title}
                  className="blog-card-image"
                />
                <div className="blog-card-content">
                  <h2>{post.title}</h2>
                  <p>{post.description}</p>
                  <div className="blog-card-meta">
                    <time dateTime={post.date}>
                      {new Date(post.date).toLocaleDateString()}
                    </time>
                    <span className="reading-time">{post.readingTime} min read</span>
                  </div>
                  <div className="blog-card-tags">
                    {post.tags.map(tag => (
                      <span key={tag} className="tag">#{tag}</span>
                    ))}
                  </div>
                </div>
              </Link>
            </article>
          ))}
        </section>
      </main>
    </>
  );
}

export default Blog; 