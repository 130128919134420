import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function Footer() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleFeatureClick = (sectionId, event) => {
    event.preventDefault();
    
    if (location.pathname === '/') {
      // If we're on the home page, just scroll
      const element = document.getElementById(sectionId);
      if (element) {
        const offset = 80;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    } else {
      // If we're on another page, navigate to home and then scroll
      navigate('/', { state: { scrollTo: sectionId } });
    }
  };

  return (
    <footer className="global-footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>NEST</h3>
          <p className="footer-description">
            Your intelligent tab manager for a cleaner, more focused browsing experience
          </p>
        </div>
        
        <div className="footer-section">
          <h4>Features</h4>
          <ul>
            <li><a href="/" onClick={(e) => handleFeatureClick('backup', e)}>Backup</a></li>
            <li><a href="/" onClick={(e) => handleFeatureClick('organize', e)}>Organize</a></li>
            <li><a href="/" onClick={(e) => handleFeatureClick('snooze', e)}>Snooze</a></li>
            <li><a href="/" onClick={(e) => handleFeatureClick('notes', e)}>Notes</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Support</h4>
          <ul>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><a href="mailto:support@nestextended.com">Email Support</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Install</h4>
          <a 
            href="https://chromewebstore.google.com/detail/nest/bgjdhacmjdhmlknmkngbklnbpnjlclbe?hl=en"
            className="footer-cta"
            target="_blank"
            rel="noopener noreferrer"
          >
            Add to Chrome
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} NEST. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
