import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navigation from './components/navigation';
import Home from './components/home';
import Contact from './components/contact';
import Privacy from './components/privacy';
import Blog from './components/Blog';
import FAQ from './components/FAQ';
import BlogPost from './components/BlogPost';
import Footer from './components/footer';
import './App.css';

// ScrollToTop component to handle scrolling on navigation
function ScrollToTop() {
  const location = useLocation();
  
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'  // Use instant instead of smooth for immediate jump
    });
  }, [location.pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/faq" element={<FAQ />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
