import React, { useEffect } from 'react';
import { handleScrollAnimation } from '../scrollAnimation';
import { useLocation } from 'react-router-dom';
import SEO from './SEO';
import StructuredData from './StructuredData';

function Home() {
  const location = useLocation();

  useEffect(() => {
    handleScrollAnimation();

    // Handle scrolling when navigating from other pages
    if (location.state?.scrollTo) {
      const sectionId = location.state.scrollTo;
      const element = document.getElementById(sectionId);
      if (element) {
        const offset = 80;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;

        setTimeout(() => {
          window.scrollTo({
            top: offsetPosition,
            behavior: 'instant'
          });
        }, 100); // Small delay to ensure the page is fully loaded
      }
    }
  }, [location]);

  const handleFeatureClick = (sectionId, event) => {
    event.preventDefault();
    const element = document.getElementById(sectionId);
    if (element) {
      const offset = 80;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'instant'
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Nest - Chrome Tab Manager",
    "applicationCategory": "BrowserExtension",
    "operatingSystem": "Chrome",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "description": "Keep your browser organized and efficient with Nest, the ultimate Chrome tab manager. Features automatic backup, smart organization, tab snoozing, and notes.",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "100"
    },
    "featureList": [
      "Instant Tab Recovery",
      "Smart Organization",
      "Tab Snoozing",
      "Rich Text Notes"
    ],
    "url": "https://nestextended.com",
    "downloadUrl": "https://chromewebstore.google.com/detail/nest/bgjdhacmjdhmlknmkngbklnbpnjlclbe?hl=en"
  };

  return (
    <>
      <SEO 
        title="Nest - The Best Tab Manager for Chrome | Organize, Backup & Manage Tabs"
        description="Keep your browser organized and efficient with Nest, the ultimate Chrome tab manager. Features automatic backup, smart organization, tab snoozing, and notes. Never lose important tabs again!"
        keywords="chrome tab manager, tab organization, tab backup, browser productivity, tab management extension, chrome extension"
        canonicalUrl="/"
        image="/hero-image.png"
      />
      <StructuredData data={structuredData} />
      
      <main className="home">
        <section className="hero" aria-label="Hero section">
          <div className="hero-content">
            <h1>Transform Your Chrome Tabs Management</h1>
            <p className="sub-headline">
              Keep your browser organized and efficient. Never lose important tabs again with powerful backup, organization, and tab management features.
            </p>
            <div className="hero-cta">
              <a 
                href="https://chromewebstore.google.com/detail/nest/bgjdhacmjdhmlknmkngbklnbpnjlclbe?hl=en" 
                className="cta-button-primary" 
                target="_blank" 
                rel="noopener noreferrer"
                aria-label="Add Nest to Chrome"
              >
                Add to Chrome
              </a>
            </div>
            <nav className="hero-features" aria-label="Feature navigation">
              <a onClick={(e) => handleFeatureClick('backup', e)} href="/" className="hero-feature">
                <span className="checkmark" aria-hidden="true">✓</span>
                <span>Instant Tab Recovery</span>
              </a>
              <a onClick={(e) => handleFeatureClick('organize', e)} href="/" className="hero-feature">
                <span className="checkmark" aria-hidden="true">✓</span>
                <span>Smart Organization</span>
              </a>
              <a onClick={(e) => handleFeatureClick('snooze', e)} href="/" className="hero-feature">
                <span className="checkmark" aria-hidden="true">✓</span>
                <span>Tab Snoozing</span>
              </a>
              <a onClick={(e) => handleFeatureClick('notes', e)} href="/" className="hero-feature">
                <span className="checkmark" aria-hidden="true">✓</span>
                <span>Notes</span>
              </a>
            </nav>
          </div>
        </section>

        <article className="feature-section backup-section" id="backup" aria-labelledby="backup-heading">
          <div className="feature-content">
            <div className="feature-text">
              <h2 id="backup-heading">Never Lose Your <span className="highlight">Tabs</span> Again</h2>
              <p className="feature-description">
                Accidentally closed an important tab? NEST automatically backs up all your tabs, making <span className="highlight-white">tab restoration</span> effortless.
              </p>
              <ul className="feature-list" aria-label="Backup features">
                <li>One-click tab restoration for recently closed tabs</li>
                <li>Automatic backup of all open and closed tabs</li>
                <li>Search through your tab history easily</li>
                <li>Batch restore multiple tabs at once</li>
              </ul>
            </div>
            <div className="feature-image">
              <img src="/Backup.gif" alt="Demonstration of NEST's tab backup and restoration feature" loading="lazy" />
            </div>
          </div>
        </article>

        <section className="feature-section organize-section" id="organize">
          <div className="feature-content reverse">
            <div className="feature-text">
              <h2>Smart <span className="highlight">Tab Organization</span></h2>
              <p className="feature-description">
                Transform tab chaos into clarity. <span className="highlight-white">NEST's intelligent categorization</span> helps you organize Chrome tabs automatically.
              </p>
              <ul className="feature-list">
                <li>Automatic tab grouping by topic and domain</li>
                <li>Custom categories for perfect organization</li>
                <li>Drag-and-drop tab management</li>
                <li>Quick access to frequently used tabs</li>
              </ul>
            </div>
            <div className="feature-image">
              <img src="/TabCategories.gif" alt="NEST Tab Organization Feature" loading="lazy" />
            </div>
          </div>
        </section>

        <section className="feature-section snooze-section" id="snooze">
          <div className="feature-content">
            <div className="feature-text">
              <h2>Snooze Tabs <span className="highlight">Boost Productivity</span></h2>
              <p className="feature-description">
                Too many tabs distracting you? With Nest, you can snooze tabs and <span className="highlight-light">Restore Later</span>
              </p>
              <ul className="feature-list">
                <li>Schedule tabs to reappear at specific times</li>
                <li>Snooze entire groups of related tabs</li>
                <li>Custom snooze durations</li>
                <li>Perfect for managing reading lists and tasks</li>
              </ul>
            </div>
            <div className="feature-image">
              <img src="/Snooze.gif" alt="NEST Tab Snoozing Feature" loading="lazy" />
            </div>
          </div>
        </section>

        <section className="feature-section notes-section" id="notes">
          <div className="feature-content reverse">
            <div className="feature-text">
              <h2>Smart <span className="highlight">Tab Notes</span></h2>
              <p className="feature-description">
                Keep important information right where you need it. Add <span className="highlight-white">rich text notes</span> to any tab or group of tabs.
              </p>
              <ul className="feature-list">
                <li>Rich text formatting for detailed notes</li>
                <li>Attach notes to individual tabs or groups</li>
                <li>Quick access to all your tab notes</li>
                <li>Perfect for research and project management</li>
              </ul>
            </div>
            <div className="feature-image">
              <img src="/Notes.gif" alt="NEST Tab Notes Feature" loading="lazy" />
            </div>
          </div>
        </section>

        <section className="testimonials-section">
          <h2>Loved by Chrome Users</h2>
          <div className="testimonials-grid">
            <div className="testimonial-card">
              <p>"The best tab manager for Chrome I've ever used. Makes restoring tabs a breeze!"</p>
              <div className="user">- Chrome User</div>
            </div>
            <div className="testimonial-card">
              <p>"Finally, a tab management extension that actually works. The snooze feature is amazing!"</p>
              <div className="user">- Productivity Enthusiast</div>
            </div>
            <div className="testimonial-card">
              <p>"Perfect for managing dozens of tabs. The organization features are incredible."</p>
              <div className="user">- Web Developer</div>
            </div>
          </div>
        </section>

        <section className="cta-section">
          <div className="section-content animate-on-scroll">
            <h2 className="animate-fade-down">Ready to <span className="highlight">Transform</span><br />Your Browsing?</h2>
            <div className="cta-features animate-fade-up">
              <div className="cta-feature-item animate-fade-in">
                ✓ Instant Recovery
              </div>
              <div className="cta-feature-item animate-fade-in">
                ✓ Smart Organization
              </div>
              <div className="cta-feature-item animate-fade-in">
                ✓ Tab Snoozing
              </div>
            </div>
            <a 
              href="https://chromewebstore.google.com/detail/nest/bgjdhacmjdhmlknmkngbklnbpnjlclbe?hl=en" 
              className="cta-button animate-scale-in" 
              target="_blank" 
              rel="noopener noreferrer"
              aria-label="Install NEST Chrome Extension"
            >
              Add to Chrome - It's Free
            </a>
          </div>
        </section>
      </main>
    </>
  );
}

export default Home;
