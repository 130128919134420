import React from 'react';
import SEO from './SEO';
import StructuredData from './StructuredData';

function FAQ() {
  const faqs = [
    {
      question: "What makes Nest the best tab manager for Chrome?",
      answer: "Nest combines powerful features like automatic tab backup, smart organization, tab snoozing, and integrated notes in one seamless Chrome extension. Unlike other tab managers, Nest uses AI-powered categorization to automatically organize your tabs, provides instant recovery of closed tabs, and offers a unique tab snoozing feature for better productivity."
    },
    {
      question: "How does Nest's Chrome tab organization work?",
      answer: "Nest's intelligent tab organization system automatically groups related tabs by topic and domain. You can create custom categories, use drag-and-drop management, and access frequently used tabs quickly. The smart categorization helps reduce tab clutter and makes finding specific tabs effortless."
    },
    {
      question: "Can I take notes directly in Chrome with Nest?",
      answer: "Yes! Nest's integrated notes feature lets you add rich text notes directly to any tab or group of tabs in Chrome. You can format text, add links, and access all your notes quickly. It's perfect for research, project management, and keeping important information right where you need it."
    },
    {
      question: "How does tab snoozing work in Nest?",
      answer: "Nest's tab snoozing feature lets you temporarily hide tabs and schedule them to reappear at a specific time. You can snooze individual tabs or entire groups, set custom durations, and manage your reading list effectively. This helps reduce tab clutter and ensures you don't forget important tabs."
    },
    {
      question: "What happens to my tabs if Chrome crashes?",
      answer: "Nest automatically backs up all your tabs in real-time. If Chrome crashes, you can restore all your tabs with a single click. The backup feature includes tab history, organization structure, and associated notes, ensuring you never lose important work."
    },
    {
      question: "Is Nest better than other Chrome tab managers?",
      answer: "Nest stands out as the best Chrome tab manager by combining essential features like automatic backup, smart organization, and tab snoozing with unique capabilities like integrated notes and AI-powered categorization. It's designed for both casual users and power users who work with many tabs."
    },
    {
      question: "How can I organize my Chrome browser tabs effectively?",
      answer: "With Nest, you can organize Chrome tabs automatically using smart categorization, create custom groups, use tab snoozing to hide less important tabs, and add notes for better context. The extension provides a clean, intuitive interface for managing dozens or even hundreds of tabs efficiently."
    },
    {
      question: "Can I sync my tabs and notes across devices?",
      answer: "Yes, Nest syncs all your tabs, groups, and notes across all your Chrome browsers when you're signed in. This ensures consistent tab organization and access to your notes regardless of which device you're using."
    }
  ];

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqs.map(faq => ({
      "@type": "Question",
      "name": faq.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": faq.answer
      }
    }))
  };

  return (
    <>
      <SEO 
        title="FAQ - Nest Chrome Tab Manager | Best Tab Organization & Notes Extension"
        description="Learn how Nest helps you manage Chrome tabs, organize windows, take notes, and boost productivity. The best tab manager for Chrome with smart organization and tab snoozing."
        keywords="best tab manager chrome, chrome tab manager, chrome extension tab manager, chrome snooze tab, notes chrome extension"
        canonicalUrl="/faq"
      />
      <StructuredData data={structuredData} />
      
      <main className="faq-page">
        <section className="faq-hero">
          <h1>Frequently Asked Questions</h1>
          <p className="subtitle">Everything you need to know about the best tab manager for Chrome</p>
        </section>

        <section className="faq-content">
          {faqs.map((faq, index) => (
            <article key={index} className="faq-item">
              <h2>{faq.question}</h2>
              <p>{faq.answer}</p>
            </article>
          ))}
        </section>
      </main>
    </>
  );
}

export default FAQ; 