import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function Navigation() {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleFeatureClick = (sectionId, event) => {
    event.preventDefault();
    
    if (location.pathname === '/') {
      // If we're on the home page, just scroll
      const element = document.getElementById(sectionId);
      if (element) {
        const offset = 80; // Height of the fixed navbar
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'instant'
        });
      }
    } else {
      // If we're on another page, navigate to home and then scroll
      navigate('/', { state: { scrollTo: sectionId } });
    }
  };

  return (
    <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
      <Link to="/" className="navbar-brand">
        <span className="logo-text">NEST</span>
      </Link>
      <div className="navbar-links">
        <div className="nav-link features-dropdown">
          <span>Features</span>
          <div className="dropdown-menu">
            <a onClick={(e) => handleFeatureClick('backup', e)} href="/">Backup</a>
            <a onClick={(e) => handleFeatureClick('organize', e)} href="/">Organize</a>
            <a onClick={(e) => handleFeatureClick('snooze', e)} href="/">Snooze</a>
            <a onClick={(e) => handleFeatureClick('notes', e)} href="/">Notes</a>
          </div>
        </div>
        <Link to="/blog" className="nav-link">Blog</Link>
        <Link to="/faq" className="nav-link">FAQ</Link>
        <Link to="/contact" className="nav-link">Contact</Link>
        <Link to="/privacy" className="nav-link">Privacy</Link>
        <a 
          href="https://chromewebstore.google.com/detail/nest/bgjdhacmjdhmlknmkngbklnbpnjlclbe?hl=en" 
          className="nav-link install-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          Add to Chrome
        </a>
      </div>
    </nav>
  );
}

export default Navigation;
